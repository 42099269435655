import {Link} from 'gatsby'
import Appshell from '../components/Appshell'
import Background from '../uikit/Background'
import Button from '../uikit/Button'
import Flex from '../uikit/Flex'


const text = {
  title: 'Táto stránka neexistuje',
  description: 'Ospravedlňujeme sa, ale táto stránka neexistuje',
}

const NotFound = () => {
  return (
    <Appshell>
      <Background>
        <Flex crossAxis="center" column>
          <h1>
            {text.title}
          </h1>
          <p>
            {text.description}
          </p>
          <Button component={Link} isLink to="/">hlavná stránka</Button>
        </Flex>
      </Background>
    </Appshell>
  )
}

export default NotFound
