import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'
import ContentContainer from './ContentContainer'


const StyledBackground = styled.div`
  height: 100vh;

  &::before {
    position: fixed;
    z-index: -1;
    background-image:
      linear-gradient(${({theme}) => theme.overlays.background}, ${({theme}) => theme.overlays.background}),
      url(${({mobileBackground}) => mobileBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100vw;
    min-height: 100vh;
    content: '';
    ${media.up('mobile')(css`
      background-image:
        linear-gradient(${({theme}) => theme.overlays.background}, ${({theme}) => theme.overlays.background}),
        url(${({desktopBackground}) => desktopBackground});
      background-position: center 10%;
    `)}
  }
`

const StyledPaddedContent = styled.div`
  padding: 8rem 0 5rem;
  ${media.down('mobile')(css`
    padding-bottom: 14rem;
  `)}
`

const Background = ({desktopBackground, mobileBackground, children, ...props}) => {
  return (
    <StyledBackground
        desktopBackground={desktopBackground}
        mobileBackground={mobileBackground}
        {...props}
    >
      <StyledPaddedContent>
        <ContentContainer>
          {children}
        </ContentContainer>
      </StyledPaddedContent>
    </StyledBackground>
  )
}

Background.propTypes = {
  desktopBackground: PropTypes.string,
  mobileBackground: PropTypes.string,
  children: PropTypes.node,
}

export default Background
